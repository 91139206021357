.splash_container{
  width:100%;
  height:100vh;
  display:flex;
  flex-direction: column;
  align-content:center;
  align-items:center;
  justify-content:center;
}

h1 {
  color: orange;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.borde{       
  border-radius: 50%;
  border-color: blue;
}