body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer_item_container{
  width:30%;
}

.bg-orange{
  background-color: #f28500;
}

.color-orange{
  color: #f28500;
}
.bg-home{
  background-image: url('./components/images/home.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.input-text{
  border: none !important;
  border-bottom: 0.5px solid #858581 !important; 
 }
  
.z-index{
  z-index: 2;
}

.bg-oranges{
  background-color: #ff991c;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:22px;
}

.menu{
  color:black !important;
}

.menu:hover{
  color: white !important;
  background-color: #f28500;
}

input{
  color:black !important;
}
.max-h-100{
  max-height: 100%
}