.line {
  border: none !important;
  border-bottom: 1px solid !important; 
}
.btn-login {
  color: #f28500 !important;
  border-color: #f28500 !important;
  background-color: white !important;
}

.btn-login:hover {
  color: #fff !important;
  background-color: #f28500 !important;
  border-color: #f28500 !important;
}

.img-profile{
  width: 200px;
  height: 200px;
}
